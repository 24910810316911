<template>
  <div style="position:relative">
    <div style="position: absolute;top:16px;right: 20px;z-index: 200;">
      <el-button type="primary" :loading="btnLoading" size="small" @click="createPDF">下载进仓单</el-button>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" class="tabs">
      <el-tab-pane label="基本" name="first"></el-tab-pane>
      <el-tab-pane label="提单" name="second"></el-tab-pane>
      <el-tab-pane label="费用" name="third" v-if="$store.state.baseStore.userInfo.user_type == 2"></el-tab-pane>
      <el-tab-pane label="仓库内装" name="fifth"></el-tab-pane>
      <el-tab-pane label="特殊货物" name="cargoSpecial" v-if="firstData.cargo_type == 1"></el-tab-pane>
      <el-tab-pane label="箱信息" name="last" v-if="!(firstData.spell_type == 2 && firstData.is_master != 1)"></el-tab-pane>
      <el-tab-pane label="账单" name="bill"></el-tab-pane>
      <el-tab-pane label="文件" name="fourth"></el-tab-pane>
    </el-tabs>
    <div class="content" v-loading="loading">
      <div v-show="activeName === 'first'" >
        <div class="tabsItem">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>委托信息</span>
            </div>
            <div class="item">
              <div class="label">业务编号</div>
              <div class="value">{{ firstData.job_no | textFormat }}</div>
            </div>
            <div class="item">
              <div class="label">委托单位</div>
              <div class="value">
                {{
                  firstData.customer_name_zh
                    | textFormat
                }}
              </div>
            </div>
            <div class="item">
              <div class="label">整箱类型</div>
              <div class="value">
                {{ firstData.spell_type | spellTypeFormat }}
              </div>
            </div>
            <div class="item">
              <div class="label">货物类型</div>
              <div class="value">
                {{ firstData.cargo_type | cargoTypeFormat }}
              </div>
            </div>
            <div class="item">
              <div class="label">开航日期</div>
              <div class="value">
                {{ firstData.etd | secondFormat("LL") }}
              </div>
            </div>
            <div class="item">
              <div class="label">起运港(POL)</div>
              <div class="value">
                [{{ firstData.pol_code | textFormat }}]
                {{ firstData.pol_name_en | textFormat }}
              </div>
            </div>
            <div class="item">
              <div class="label">卸货港(POD)</div>
              <div class="value">
                [{{ firstData.pod_code | textFormat }}]
                {{ firstData.pod_name_en | textFormat }}
              </div>
            </div>
            <div class="item">
              <div class="label">交货地</div>
              <div class="value">
                [{{ firstData.delivery_place_code | textFormat }}]
                {{ firstData.delivery_place_name_en | textFormat }}
              </div>
            </div>
            <div class="item">
              <div class="label">航线</div>
              <div class="value">
                <div class="value">
                  {{
                    firstData.shipping_line_name_en
                      | priorFormat(firstData.shipping_line_name_zh, LOCALE)
                  }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="label">船公司</div>
              <div class="value">
                <div class="value">
                  {{
                    firstData.shipping_company_name_en
                      | priorFormat(firstData.shipping_company_name_zh, LOCALE)
                  }}
                </div>
              </div>
            </div>
          </el-card>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>箱货信息</span>
            </div>
            <div class="item">
              <div class="label">件数</div>
              <div class="value">{{ firstData.entrust_quantity | textFormat }}</div>
            </div>
            <div class="item">
              <div class="label">毛重</div>
              <div class="value">
                {{ firstData.entrust_gross_weight | textFormat }}KGS
              </div>
            </div>
            <div class="item">
              <div class="label">净重</div>
              <div class="value">
                {{ firstData.net_weight | textFormat }}KGS
              </div>
            </div>
            <div class="item">
              <div class="label">体积</div>
              <div class="value">{{ firstData.entrust_volume | textFormat }}CBM</div>
            </div>
            <div class="item">
              <div class="label">包装</div>
              <div class="value">
                {{ `[${firstData.packing_code}] ${firstData.packing_name_en}` | textFormat }}
              </div>
            </div>
            <div class="item">
              <div class="label">小件数</div>
              <div class="value">
                {{ firstData.inside_quantity | textFormat }}
              </div>
            </div>
            <div class="item">
              <div class="label">英文品名</div>
              <div class="value" :title="firstData.cargo_name_en | textFormat">
                {{ firstData.cargo_name_en | textFormat }}
              </div>
            </div>
            <div class="item">
              <div class="label">中文品名</div>
              <div class="value" :title="firstData.cargo_name_zh | textFormat">
                {{ firstData.cargo_name_zh | textFormat }}
              </div>
            </div>
            <div class="item">
              <div class="label">唛头</div>
              <div class="value" :title="firstData.marks | textFormat">
                {{ firstData.marks | textFormat }}
              </div>
            </div>
            <div class="item">
              <div class="label">HS编号</div>
              <div class="value">{{ firstData.hs_code | textFormat }}</div>
            </div>
          </el-card>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>附加信息</span>
            </div>
            <div class="item">
              <div class="label">进仓编号</div>
              <div class="value">{{ firstData.warehouse_no | textFormat }}</div>
            </div>
            <template v-if="firstData.spell_type != 2 || (firstData.spell_type == 2 && firstData.is_master == 1)">
              <div class="item">
                <div class="label">运输条款</div>
                <div class="value">
                  {{ firstData.master_shipping_terms | shipping_termsFormat }}
                </div>
              </div>
              <div class="item">
                <div class="label">运费条款</div>
                <div class="value">{{  firstData.master_fee_terms | fee_termsFormat }}</div>
              </div>
            </template>
            <template v-else>
              <div class="item">
                <div class="label">运输条款</div>
                <div class="value">
                  {{ firstData.small_fee_terms | shipping_termsFormat }}
                </div>
              </div>
              <div class="item">
                <div class="label">运费条款</div>
                <div class="value">{{  firstData.small_shipping_terms | fee_termsFormat }}</div>
              </div>
            </template>
            <div class="item">
              <div class="label">贸易条款</div>
              <div class="value">{{ firstData.trade_terms | trade_termsFormat }}</div>
            </div>
            <div class="item">
              <div class="label">销售</div>
              <div class="value copy_text">
                <span>{{
                  firstData.sale_name
                    | textFormat
                }}</span>

                <span class="mainColor cursor" @click="hadleCopy('销售',firstData.sale_email,firstData.sale_mobile)" > 联系方式 </span>
                
              </div>
            </div>
            <div class="item">
              <div class="label">操作员</div>
              <div class="value copy_text">
                <span>{{
                  firstData.operator_name
                    | textFormat
                }}</span>
                  <span @click="hadleCopy('操作',firstData.operator_email,firstData.operator_mobile)" class="mainColor cursor" > 联系方式 </span>
              </div>
            </div>
            <div class="item">
              <div class="label">客服</div>
              <div class="value copy_text">
                <span>{{
                  firstData.customer_service_name
                    | textFormat
                }}</span>
                  <span @click="hadleCopy('客服',firstData.customer_service_email,firstData.customer_service_mobile)" class="mainColor cursor" > 联系方式 </span>
              </div>

            </div>
          </el-card>
        </div>
        <customerMessage
          ref="customerMessageRef"
        ></customerMessage>
      </div>
      <div v-show="activeName === 'second'">
        <div class="nomsg" v-if="!lad" style="padding:20px">暂无提单信息，请耐心等候</div>
        <template v-else>
          <div class="lading">
            <span>提单方式：
              <span v-if="lad.bill_mode==1">ORIGINAL</span>
              <span v-if="lad.bill_mode==2">SURRENDERED</span>
              <span v-if="lad.bill_mode==3">SEA WAYBILL</span>
              <span v-if="lad.bill_mode==4">等通知电放</span>
            </span>
            <span style="padding-left: 100px">
              <el-button type="info" size="small" v-if="lad.release_status==1">待审核</el-button>
              <el-button type="danger" size="small" v-if="lad.release_status==2">已扣单</el-button>
              <el-button type="success" size="small" v-if="lad.release_status==3">可放单</el-button>
            </span>
            <span style="padding-left: 20px">
              <el-button type="info" size="small" :disabled="true" v-if="lad.bill_status < 2">开始对单</el-button>
              <el-button type="primary" size="small" v-if="lad.bill_status==2" @click="startReconciliation"> {{reconciliationBol? '完成对单':'开始对单'}} </el-button>
            </span>
          </div>
          <div class="tabsItem">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>收发信息</span>
              </div>
              <el-form :show-message="false" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="10px" :disabled="!reconciliationBol"
                class="demo-ruleForm">
                <div class="form_div_box">
                  <!-- 发货人 -->
                  <div class="form_item">
                    <div class="lable_button">
                      <span>发货人</span>
                    </div>
                    <el-form-item>
                      <el-input v-model="ruleForm.shipper.user_name" placeholder="发货人姓名" size="small">
                        <i class="el-icon-user el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="ruleForm.shipper.address" placeholder="发货人地址" size="small">
                        <i class="el-icon-location el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="ruleForm.shipper.telephone" placeholder="联系电话" size="small">
                        <i class="el-icon-phone el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="ruleForm.shipper.fax" placeholder="传真" size="small">
                        <i class="el-icon-chat-dot-square el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="ruleForm.shipper.email" placeholder="邮箱" size="small">
                        <i class="el-icon-message el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                  </div>
                  <!-- 收货人 -->
                  <div class="form_item">
                    <div class="lable_button">
                      <span>收货人</span>
                    </div>
                    <el-form-item>
                      <el-input v-model="ruleForm.consignee.user_name" placeholder="收货人姓名" size="small">
                        <i class="el-icon-user el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="ruleForm.consignee.address" placeholder="收货人地址" size="small">
                        <i class="el-icon-location el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="ruleForm.consignee.telephone" placeholder="联系电话" size="small">
                        <i class="el-icon-phone el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="ruleForm.consignee.fax" placeholder="传真" size="small">
                        <i class="el-icon-chat-dot-square el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="ruleForm.consignee.email" placeholder="邮箱" size="small">
                        <i class="el-icon-message el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                  </div>
                  <!-- 通知人 -->
                  <div class="form_item">
                    <div class="lable_button">
                      <span>通知人</span>
                    </div>
                    <el-form-item>
                      <el-input v-model="ruleForm.notify_party.user_name" placeholder="通知人姓名" size="small">
                        <i class="el-icon-user el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="ruleForm.notify_party.address" placeholder="通知人地址" size="small">
                        <i class="el-icon-location el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="ruleForm.notify_party.telephone" placeholder="联系电话" size="small">
                        <i class="el-icon-phone el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="ruleForm.notify_party.fax" placeholder="传真" size="small">
                        <i class="el-icon-chat-dot-square el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-input v-model="ruleForm.notify_party.email" placeholder="邮箱" size="small">
                        <i class="el-icon-message el-input__icon" slot="prefix"></i>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
              </el-form>
            </el-card>
            <el-card class="box-card ">
              <div slot="header" class="clearfix">
                <span>运输信息</span>
              </div>
              <div class="item">
                <div class="label">船名</div>
                <div class="value">
                  <div class="value">{{ lad.vessel | textFormat }}</div>
                </div>
              </div>
              <div class="item">
                <div class="label">航次</div>
                <div class="value">
                  <div class="value">
                    {{ lad.voyage_flight | textFormat }}
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="label">S/O NO.</div>
                <div class="value">
                  <div class="value">{{ lad.so_no | textFormat }}</div>
                </div>
              </div>
              <div class="item">
                <div class="label">提单流水号</div>
                <div class="value">
                  <div class="value">{{ lad.bill_no | textFormat }}</div>
                </div>
              </div>
              <div class="item">
                <div class="label">截单日期</div>
                <div class="value">
                  <div class="value">{{ lad.dod | secondFormat("LL") }}</div>
                </div>
              </div>
              <div class="item">
                <div class="label">截关日期</div>
                <div class="value">
                  <div class="value">{{ lad.cod | secondFormat("LL") }}</div>
                </div>
              </div>
              <div class="item">
                <div class="label">提单起运港</div>
                <div class="value">
                  <div class="value">{{ lad.pol_name_en | textFormat }}</div>
                </div>
              </div>
              <div class="item">
                <div class="label">提单卸货港</div>
                <div class="value">
                  <div class="value">{{ lad.pod_name_en | textFormat }}</div>
                </div>
              </div>
              <div class="item">
                <div class="label">提单交货地</div>
                <div class="value">
                  <div class="value">{{ lad.delivery_place_name_en | textFormat }}</div>
                </div>
              </div>
              <template v-if="firstData.spell_type != 2 || (firstData.spell_type == 2 && firstData.is_master == 1)">
                <div class="item">
                  <div class="label">运费条款</div>
                  <div class="value">{{  lad.master_fee_terms | fee_termsFormat }}</div>
                </div>
                <div class="item">
                  <div class="label">运输条款</div>
                  <div class="value">
                    {{ lad.master_shipping_terms | shipping_termsFormat }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="item">
                  <div class="label">运费条款</div>
                  <div class="value">{{  lad.small_fee_terms | fee_termsFormat }}</div>
                </div>
                <div class="item">
                  <div class="label">运输条款</div>
                  <div class="value">
                    {{ lad.master_shipping_terms | shipping_termsFormat }}
                  </div>
                </div>
              </template>
              <div class="item">
                <div class="label">提单份数</div>
                <div class="value">
                  <div class="value">{{ lad.bill_num | textFormat }}</div>
                </div>
              </div>
              <div class="item">
                <div class="label">签发地点</div>
                <div class="value">
                  <div class="value">
                    <template v-if="lad.master_issue_place">{{ `[${lad.master_issue_country_name_en}] ${lad.master_issue_place_name_en}` | textFormat }}</template>
                    <template v-else>-</template>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="label">签发日期</div>
                <div class="value">
                  <div class="value">{{ lad.issue_date | secondFormat }}</div>
                </div>
              </div>
              <div class="item">
                <div class="label">提单备注</div>
                <div class="value">
                  <div class="value">{{ lad.bill_remarks | textFormat }}</div>
                </div>
              </div>
            </el-card>
            <el-card class="box-card ">
              <div slot="header" class="clearfix">
                <span>货物信息</span>
              </div>
              <div class="item">
                <div class="label">提单件数</div>
                <div class="value">
                  <div class="value">{{ lad.actual_quantity | textFormat }}</div>
                </div>
              </div>
              <div class="item">
                <div class="label">包装</div>
                <div class="value">
                  <div class="value">
                    {{ `[${lad.packing_code}] ${lad.packing_name_en}` | textFormat }}
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="label">毛重</div>
                <div class="value">
                  <div class="value">{{ lad.actual_gross_weight | textFormat }} KGS</div>
                </div>
              </div>
              <div class="item">
                <div class="label">体积</div>
                <div class="value">
                  <div class="value">{{ lad.actual_volume | textFormat }} CBM</div>
                </div>
              </div>
              <div class="item">
                <div class="label">英文品名</div>
                <div class="value">
                  <div class="value">{{ lad.cargo_name_en | textFormat }}</div>
                </div>
              </div>
              <div class="item">
                <div class="label">唛头</div>
                <div class="value">
                  <div class="value">{{ lad.marks | textFormat }}</div>
                </div>
              </div>
              <div class="item">
                <div class="label">件数大写</div>
                <div class="value">
                  <div class="value">{{ lad.num_capitalize | textFormat }}</div>
                </div>
              </div>
            </el-card>
          </div>
          <el-card class="box-card" style="margin-top:10px">
            <div slot="header" class="clearfix">
              <span>海外到港信息</span>
            </div>
            <div class="rows">
              <div class="item">
                <div class="label">拆箱状态</div>
                <div class="value">
                  <div class="value" v-if="lad.is_devanning === 0 && lad.is_devanning !==''">未拆箱</div>
                  <div class="value" v-else-if="lad.is_devanning == 1">已拆箱</div>
                  <div class="value" v-else>-</div>
                </div>
              </div>
              <div class="item">
                <div class="label">箱号</div>
                <div class="value">
                  <div class="value">{{ lad.box_no | textFormat }}</div>
                </div>
              </div>
              <div class="item">
                <div class="label">拆箱时间</div>
                <div class="value">
                  <div class="value">
                    {{ lad.devanning_date | secondFormat("LL") }}
                  </div>
                </div>
              </div>
            </div>
          
          <div class="rows">
              <div class="item">
                <div class="label">换单（D/O released）</div>
                <div class="value">
                  <div class="value">{{ lad.change_date | secondFormat("LL") }} </div>
                </div>
              </div>
              <div class="item">
                <div class="label">派货（Transhipment）</div>
                <div class="value">
                  <div class="value" v-if="lad.delivery_status == 1">仓库中</div>
                  <div class="value" v-else-if="lad.delivery_status == 2">在派送</div>
                  <div class="value" v-else>-</div>
                </div>
              </div>
              <div class="item" v-if="lad.pick_up_type == 1">
                <div class="label">提货时间</div>
                <div class="value">
                  <div class="value">{{ lad.pick_up_time | secondFormat("LL") }}</div>
                </div>
              </div>
              <div class="item" v-else>
                <div class="label">派货</div>
                <div class="value">
                  <div class="value">EDT {{ lad.delivery_etd | secondFormat("LL") }}<span style="margin-left:30px">EDA {{ lad.delivery_eta | secondFormat("LL") }}</span></div>
                </div>
              </div>
            </div>
          </el-card>
        </template>
      </div>
      <div v-show="activeName === 'third'">
        <el-card class="box-card width100">
          <div slot="header" class="clearfix">
            <span>应收</span>
          </div>
          <el-table
            :data="thirdData.receivable_list"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="currency_code"
              label="币种"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              label="费用名称"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                {{
                  scope.row.fee_name
                    | textFormat
                }}
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="单位">
                <template slot-scope="scope">{{scope.row.measure_unit_code | unitArrFormat}}</template>
            </el-table-column>
            <el-table-column
              prop="quantity"
              label="数量"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="unit_rate"
              label="单价"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="含税价">
                <template slot-scope="scope">{{(scope.row.include_tax*1).toFixed(2)    | textFormat}}</template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="不含税价">
                <template slot-scope="scope">{{(scope.row.except_tax*1).toFixed(2)  | textFormat}}</template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="税率">
                <template slot-scope="scope">{{scope.row.val_rate | textFormat}}</template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="税额">
                <template slot-scope="scope">{{scope.row.val_amount | textFormat}}</template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="备注">
                <template slot-scope="scope">{{scope.row.remarks | textFormat}}</template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="账单号">
                <template slot-scope="scope">{{scope.row.bill_no | textFormat}}</template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-card class="box-card width100" style="margin-top: 20px">
          <div slot="header" class="clearfix">
            <span>应付</span>
          </div>
          <el-table :data="thirdData.payable_list" border style="width: 100%">
            <el-table-column
              prop="currency_code"
              label="币种"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="currency_code"
              label="费用名称"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                {{
                  scope.row.fee_name
                    | textFormat
                }}
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="单位">
                <template slot-scope="scope">{{scope.row.measure_unit_code | unitArrFormat}}</template>
            </el-table-column>
            <el-table-column
              prop="quantity"
              label="数量"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="unit_rate"
              label="单价"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="含税价">
                <template slot-scope="scope">{{(scope.row.include_tax*1).toFixed(2)    | textFormat}}</template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="不含税价">
                <template slot-scope="scope">{{(scope.row.except_tax*1).toFixed(2)  | textFormat}}</template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="税率">
                <template slot-scope="scope">{{scope.row.val_rate | textFormat}}</template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="税额">
                <template slot-scope="scope">{{scope.row.val_amount | textFormat}}</template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="备注">
                <template slot-scope="scope">{{scope.row.remarks | textFormat}}</template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="账单号">
                <template slot-scope="scope">{{scope.row.bill_no | textFormat}}</template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
      <div v-show="activeName === 'fourth'">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="handleAddDataBank()"
          >上传</el-button
        >
        <div class="line"></div>
        <el-table :data="fourthData" style="width: 100%" border>
          <el-table-column label="文件名称" prop="name">
            <div slot-scope="scope">
              <el-button type="text" @click="handle_open(scope.row.file_url)">{{
                scope.row.file_name
              }}</el-button>
            </div>
          </el-table-column>
          <el-table-column label="文件类型" prop="name">
            <div slot-scope="scope">
              {{ scope.row.file_type | file_type_filter }}
            </div>
          </el-table-column>
          <el-table-column label="文件格式" prop="format">
            <div slot-scope="scope">
              {{ scope.row.format | textFormat }}
            </div>
          </el-table-column>
          <el-table-column label="文件大小" prop="size">
            <div slot-scope="scope">
              <div v-if="scope.row.size">
                {{ scope.row.size | textFormat }}KB
              </div>
              <div v-else>-</div>
            </div>
          </el-table-column>
          <el-table-column label="创建日期" prop="created_time">
            <div slot-scope="scope">
              {{ scope.row.created_time | secondFormat }}
            </div>
          </el-table-column>
          <el-table-column
            label="备注"
            prop="remarks"
            :show-overflow-tooltip="true"
          >
            <div slot-scope="scope">
              {{ scope.row.remarks | textFormat }}
            </div>
          </el-table-column>
          <el-table-column label="操作" width="50" align="center">
              <template slot-scope="scope">
                <el-button type="text" icon="el-icon-delete" size="small" @click.native.prevent="deleteRow(scope.row.id)"></el-button>
              </template>
            </el-table-column>
        </el-table>
      </div>
      <div v-show="activeName === 'bill'">
        <bill ref="billRef"></bill>
      </div>
      <div v-show="activeName == 'fifth'">
        <div>
            <div class="roomBox">
              <p class="boxTit">内装仓库</p>
              <div class="row_flex">
                <div class="item">
                  <div class="label">进仓编号</div>
                  <div class="value">{{ hourse_msg.warehouse_no | textFormat }}</div>
                </div>
                <div class="item">
                  <div class="label">第三方进仓编号</div>
                  <div  style="display:flex">
                    <div class="value" style="width:260px" >{{ hourse_msg.third_warehouse_no | textFormat }}</div>
                    <!-- <span  class="el-icon-edit cursor mainColor" @click="inroom_edit=true" ></span> -->
                  </div>
                </div>
              </div>
              <div class="row_flex">
                <div class="item">
                  <div class="label">仓库类型</div>
                  <div class="value">{{ hourse_msg.kind | houseArrType }}</div>
                </div>
                <div class="item" v-if="hourse_msg.kind == 1">
                  <div class="label">仓库</div>
                  <div class="value">{{ hourse_msg.name | textFormat }}</div>
                </div>
              </div>
              <template v-if="hourse_msg.kind == 1">
                <div class="row_flex" >
                  <div class="item">
                    <div class="label">联系人</div>
                    <div class="value">{{ hourse_msg.contacts | textFormat }}</div>
                  </div>
                  <div class="item">
                    <div class="label">电话</div>
                    <div class="value">{{ hourse_msg.mobile | textFormat }}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="label">预计到货日期</div>
                  <!-- <el-date-picker
                    style="width:300px"
                    :clearable='false'
                    v-model="hourse_msg.expected_arrival_date"
                    type="datetime"
                    @change="time_change"
                    placeholder="选择预计到货日期">
                  </el-date-picker> -->
                  <div class="value">{{ hourse_msg.expected_arrival_date | secondFormat('LLLL') }}</div>
                </div>
              </template>
              <template v-if="hourse_msg.kind == 2">
                <div class="row_flex">
                  <div class="item">
                    <div class="label">派送方式</div>
                    <div class="value">{{ hourse_msg.delivery_method | delivery_type }}</div>
                  </div>
                  <div class="item">
                    <div class="label" style="width:80px">仓库编号</div>
                    <div class="value">{{ hourse_msg.code | textFormat }}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="label">预计到货日期</div>
                  <div class="value">{{ hourse_msg.expected_arrival_date | secondFormat('LLLL') }}</div>
                </div>
              </template>
              <div class="item">
                <div class="label">仓库地址</div>
                <div class="value">{{ hourse_msg.address | textFormat }}</div>
              </div>
              <div class="item">
                <div class="label">{{hourse_msg.kind == 1 ? '内装备注':'备注'}} </div>
                <div class="value">{{ hourse_msg.remarks | textFormat }}</div>
              </div>
            </div>
            <div class="roomBox">
              <p class="boxTit">海外仓库</p>
              <div class="row_flex">
                <div class="item">
                  <div class="label">仓库类型</div>
                  <div class="value">{{ inroom_msg.kind | houseArrType }}</div>
                </div>
                <div class="item" v-if="inroom_msg.kind == 1">
                  <div class="label">仓库</div>
                  <div class="value">{{ inroom_msg.name | textFormat }}</div>
                </div>
              </div>
              <div class="row_flex" v-if="inroom_msg.kind == 1">
                <div class="item">
                  <div class="label">联系人</div>
                  <div class="value">{{ inroom_msg.contacts | textFormat }}</div>
                </div>
                <div class="item">
                  <div class="label">电话</div>
                  <div class="value">{{ inroom_msg.mobile | textFormat }}</div>
                </div>
              </div>
              <div class="row_flex" v-if="inroom_msg.kind == 2">
                <div class="item">
                  <div class="label">派送方式</div>
                  <div class="value">{{ inroom_msg.delivery_method | delivery_type }}</div>
                </div>
                <div class="item">
                  <div class="label">仓库编号</div>
                  <div class="value">{{ inroom_msg.code | textFormat }}</div>
                </div>
              </div>
              <div class="row_flex">
                <div class="item">
                  <div class="label">到仓拆柜日期</div>
                  <div class="value">{{ inroom_msg.unpacking_date | secondFormat('LLLL') }}</div>
                </div>
                <div class="item">
                  <div class="label">派送预约日期</div>
                  <div class="value">{{ inroom_msg.appointment_date | secondFormat('LLLL') }}</div>
                </div>
              </div>
              <div class="item">
                <div class="label">仓库地址</div>
                <div class="value">{{ inroom_msg.address | textFormat }}</div>
              </div>
              <div class="item">
                <div class="label">{{ inroom_msg.kind == 1 ? '内装备注':'备注'}}</div>
                <div class="value">{{ inroom_msg.remarks | textFormat }}</div>
              </div>
            </div>
        </div>
      </div>
      <div v-show="activeName === 'last'">
        <div>
          <div class="total">
            <span>总件数：{{ totalNo }}</span>
            <span style="padding:0 100px">总毛重：{{ totalWeight }} KGS</span>
            <span>总体积：{{ totalVolum }} CBM</span>
          </div>
          <el-table :data="packList" style="width: 100%" border >
            <el-table-column label="箱型箱量" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.box_model == 1">20`</span>
                <span v-if="scope.row.box_model == 2">40`</span>
                <span v-if="scope.row.box_model == 3">45`</span>
                <span>{{scope.row.box_type}}</span>
              </template>
            </el-table-column>
            <el-table-column label="件数">
              <template slot-scope="scope">
                <span>{{scope.row.entrust_quantity | textFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column label="毛重（KGS）">
              <template slot-scope="scope">
                <span>{{scope.row.entrust_gross_weight | textFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column label="体积（CBM）">
               <template slot-scope="scope">
                <span>{{scope.row.entrust_volume | textFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column label="英文品名" width="300px">
              <template slot-scope="scope">
                <pre style="width: 250px">{{ scope.row.cargo_name_en | textFormat}}</pre>
              </template>
            </el-table-column>
            <el-table-column label="备注">
              <template slot-scope="scope">
                <span>{{scope.row.remarks | textFormat}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-show="activeName === 'cargoSpecial'">
        <div>
          <el-table :data="specialGoodsList" style="width: 100%" border>
            <el-table-column label="特殊货物编号" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span>{{scope.row.special_goods_no | textFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column label="英文品名">
              <template slot-scope="scope">
                <span>{{scope.row.cargo_name_en | textFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column label="中文品名">
              <template slot-scope="scope">
                <span>{{scope.row.cargo_name_zh | textFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column label="联合国编号">
               <template slot-scope="scope">
                <span>{{scope.row.un_no | textFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column label="危险货物类别">
               <template slot-scope="scope">
                <span>{{scope.row.classify | textFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column label="副危险类别">
               <template slot-scope="scope">
                <span>{{scope.row.subsidiary_risks | textFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column label="货物包装类别">
               <template slot-scope="scope">
                <span>{{scope.row.packing_group | textFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column label="总毛重">
               <template slot-scope="scope">
                <span>{{scope.row.gross_weight | textFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column label="总净重">
               <template slot-scope="scope">
                <span>{{scope.row.net_weight | textFormat}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click.native.prevent="viewRow(scope.row.id)">查看明细</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog
      title="文件上传"
      :visible.sync="uploadingFileDialog"
      width="700px"
    >
      <uploadDialog
        @handleClose="handleCloseDialog"
        v-if="uploadingFileDialog"
      ></uploadDialog>
    </el-dialog>
    <el-dialog :title="dialogMsg.title+'人员联系方式'" width="600px"   :visible.sync="userMsgDialog_show">
      <div>
        <div class="item_msg">
          <i class="el-icon-phone"></i>
          <span>{{ dialogMsg.tel | textFormat  }}</span>
          <i></i>
        </div>
        <div class="item_msg">
          <i class="el-icon-message"></i>
          <span>{{ dialogMsg.email | textFormat }}</span>
          <i v-if="dialogMsg.email" class="mainColor cursor el-icon-copy-document" @click="handleCopyTxt(dialogMsg.email)" ></i>
        </div>
      </div>
    </el-dialog>
    <!-- 订舱单的PDF   -->
    <div >
        <hkDdf id="pdf" ref="imageWrapper"  :class="{'showhtml':btnLoading,'hidehtml':!btnLoading}"  :PDFDataMsg="PDFDataMsg" />
    </div>
    <el-dialog title="特殊货物信息" :visible.sync="dialogFormVisible" width="1000px">
      <div class="tabsItem" v-if="dialogFormVisible">
        <el-card class="box-card" style="width:49%;border:none" shadow="never" >
          <div class="item">
            <div class="label">联合国编号</div>
            <div class="value">
              <div class="value">{{ spd.un_no | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">危险货物类别</div>
            <div class="value">
              <div class="value">
                {{ spd.dangerous.classify | textFormat }}
              </div>
            </div>
          </div>
          <div class="item">
            <div class="label">副危险类别</div>
            <div class="value">
              <div class="value">{{ spd.dangerous.subsidiary_risks | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">货物包装类别</div>
            <div class="value">
              <div class="value">{{ spd.dangerous.packing_group | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">液体容积</div>
            <div class="value">
              <div class="value">{{ spd.liquid_volume | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">Emergency Schedule</div>
            <div class="value">
              <div class="value">{{ spd.dangerous.emergency_schedule | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">Flashpoint</div>
            <div class="value">
              <div class="value">{{ spd.dangerous.flashpoint | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">隔离</div>
            <div class="value">
              <div class="value">{{ spd.dangerous.segregation | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">危险货物状态</div>
            <div class="value">
              <div class="value">{{ spd.status | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">HS编码</div>
            <div class="value">
              <div class="value">{{ spd.hs_code | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">正确运输名称</div>
            <div class="value">
              <div class="value">{{ spd.dangerous.psn || spd.dangerous.pscn | textFormat }}</div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card" style="width:49%;border:none" shadow="never">
          <div class="item">
            <div class="label">国内24小时紧急联系人</div>
            <div class="value">
              <div class="value">{{ spd.in_emergency_contact | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">国内电话号码</div>
            <div class="value">
              <div class="value">
                {{ spd.in_emergency_contact_tel | textFormat }}
              </div>
            </div>
          </div>
          <div class="item">
            <div class="label">国外24小时紧急联系人</div>
            <div class="value">
              <div class="value">{{ spd.out_emergency_contact | textFormat }} </div>
            </div>
          </div>
          <div class="item">
            <div class="label">国外电话号码</div>
            <div class="value">
              <div class="value">{{ spd.out_emergency_contact_tel | textFormat }} </div>
            </div>
          </div>
          <div class="item">
            <div class="label">CAS NO</div>
            <div class="value">
              <div class="value">{{ spd.cas_no | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">海洋污染</div>
            <div class="value">
              <div class="value">{{ spd.is_marine_pollution == 1 ? 'Y':'N' | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">总毛重</div>
            <div class="value">
              <div class="value">{{ spd.gross_weight | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">总净重</div>
            <div class="value">
              <div class="value">{{ spd.net_weight | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">外包装类型</div>
            <div class="value">
              <div class="value" v-if="spd.out_packing_type">{{ `[${spd.out_packing_code}] ${spd.out_packing_name_en}`  | textFormat }}</div>
              <div class="value" v-else>-</div>
            </div>
          </div>
          <div class="item">
            <div class="label">外包装件数</div>
            <div class="value">
              <div class="value">{{ spd.out_packing_quantity | textFormat }}</div>
            </div>
          </div>
          <div class="item">
            <div class="label">备注</div>
            <div class="value">
              <div class="value">{{ spd.remarks | textFormat }}</div>
            </div>
          </div>
        </el-card>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="dialogFormVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { FreightClause, TradeTerms, TransportationTerms, unitArr } from "@/baseUtils/enumValue";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import uploadDialog from "~bac/components/dealManagement/myEntrust/detailsOrdered/uploadingFileDialog";
import bill from './billdetail.vue';
import customerMessage from './customerMessage.vue';
import hkDdf from './hkpdf.vue';
export default {
  name: "tabs",
  components: { uploadDialog ,hkDdf , bill ,customerMessage},
  data() {
    return {
      btnLoading:false,
      PDFDataMsg:{},
      userMsgDialog_show:false,
      dialogFormVisible: false, //特殊货物信息弹框
      dialogMsg:{
        title:"",
        tel:"",
        email:""
      },
      activeName: "first",
      firstData: {},
      lad: {}, // 提单信息
      spd: {
        dangerous:{
          classify:'',
          packing_group:'',
          subsidiary_risks:''
        }
      },//特殊货物信息
      reconciliationBol:false,
      thirdData: {
        receivable_list: [],
        payable_list: [],
      },
      fourthData: [],
      specialGoodsList: [], // 特殊货物列表
      loading: false,
      uploadingFileDialog: false,
      packList: [],
      type:null,
      hourse_msg:{},
      inroom_msg:[],
      inroom_edit:false,
      form:{
        inroom_number:""
      },
      ruleForm: {
        shipper: {
          user_name: "",
          fax: "",
          address: "",
          telephone: "",
          email: "",
        },
        consignee: {
          user_name: "",
          fax: "",
          address: "",
          telephone: "",
          email: "",
        },
        notify_party: {
          user_name: "",
          fax: "",
          address: "",
          telephone: "",
          email: "",
        },
      },
      rules: {}
    };
  },
  filters: {
    shipping_termsFormat(input){
      let current = TransportationTerms.find(item => {
        return item.value == input;
      });
      if (current) {
          return `${current.label}`
      } else {
          return "-";
      }
    },
    trade_termsFormat(input){
      let current = TradeTerms.find(item => {
        return item.value == input;
      });
      if (current) {
          return `${current.label}`
      } else {
          return "-";
      }
    },
    unitArrFormat(input, label = 'label') { //单位过滤
      if (!input && input !== 0) {
          return '-'
      }
      let current = unitArr.find(item => {
          return item.value == input
      });
      if (current) {
          return current[`${label}`]
      } else {
          return input
      }
    },
    fee_termsFormat(input){
      let current = FreightClause.find(item => {
        return item.value == input;
      });
      if (current) {
          return `[ ${current.code}] ${current.label}`
      } else {
          return "-";
      }
    },
    my_textFormat(input) {
      try {
        if (
          input === "" ||
          input === "null" ||
          input === "undefined"
        ) {
          return "暂无信息";
        }
        return input;
      } catch (err) {
        return input;
      }
    },
    spellTypeFormat(val) {
      let obj = {
        1: "整箱",
        2: "拼箱",
        3: "其他",
      };
      return obj[val] || "-";
    },
    houseArrType(val) {
      let obj = {
        1: "普通仓库",
        2: "亚马逊仓库"
      };
      return obj[val] || "-";
    },
    delivery_type(val) {
      let obj = {
        1: "海卡",
        2: "海派",
        3: "快递",
        4: "自提",
      };
      return obj[val] || "-";
    },
    payFormat(val) {
      let obj = {
        1: "司机支付",
        2: "客户支付"
      };
      return obj[val] || "-";
    },
    cargoTypeFormat(val) {
      let obj = {
        1: "危险品",
        2: "普货",
      };
      return obj[val] || "-";
    },
    file_type_filter(val) {
      let str = "-";
      if (val == 1) {
        str = "海运委托书";
      }
      if (val == 2) {
        str = "MSDS";
      }
      if (val == 3) {
        str = "危包证";
      }
      if (val == 4) {
        str = "商检报告";
      }
      if (val == 5) {
        str = "其他";
      }
       if (val == 6) {
        str = "发货人电子章";
      }
       if (val == 7) {
        str = "非危鉴定";
      }
      return str;
    },
  },
  mounted() {
    this.type = this.$route.query.type
    this.$nextTick(()=>{
      this.getList();
      this.getHorseMsg();
    })
  },
  methods: {
    startReconciliation(){ //开始对单
      if(this.reconciliationBol){
        this.$confirm(`确认完成对单?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
        .then(() => {
            this.loading = true;
            let params = {
                bill_id: this.lad.id,
                bill_status: 3,
                shipper: JSON.stringify(this.ruleForm.shipper),
                consignee: JSON.stringify(this.ruleForm.consignee),
                notify_party: JSON.stringify(this.ruleForm.notify_party),
            }
            this.$store.dispatch('baseConsole/ladingBillUpdate',params).then((res) => {
                this.loading = false;
                if (res.success) {
                    this.getList()
                }
            });
        })
        .catch((err) => {console.log(err)});
      }else{
        
        this.reconciliationBol = true;
      }
    },
    async createPDF(){
      if(!this.hourse_msg.expected_arrival_date){
        this.$alert('预计到货日期确认后才可打印进仓单！', '提示', {
          confirmButtonText: '确定',
          callback: (action) => {
            this.btnLoading = false;
          },
        });
        return;
      }
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.shippingOrder.down",
        content: JSON.stringify({
          id: this.$route.query.id || ''
        }),
      });
      if (this.btnLoading) return;
      this.btnLoading = true;
      let res=await this.$store.dispatch("baseConsole/getSeaWordInfo",{ order_id:this.$route.query.id  })
      console.log(res.data);
      this.PDFDataMsg=res.data
      setTimeout(()=>{
        window.pageYOffset = 0;
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        let _dom = document.getElementById(`pdf`);
          html2canvas(_dom, {
            allowTaint: true,
            useCORS: true,
            scale: 1,
            dpi: 182, //导出图片清晰度
          }).then((canvas) => {
            console.log(canvas);
            this.handleCanvas(canvas);
          });
        },2000)
      
    },
    handleCanvas(ccc) {
     
      let PDF = new JsPDF("p", "pt", "a4");
      let canvas = ccc;
      let contentWidth = canvas.width;
      let contentHeight = canvas.height;
      //一页pdf显示html页面生成的canvas高度;
      let pageHeight = contentWidth * (595 / 840);
      //未生成pdf的html页面高度
      let leftHeight = contentHeight;
      //页面偏移
      let position = 0;
      let imgWidth = 595;
      let imgHeight = 595 * (contentHeight / contentWidth);
      //let imgHeight = 420;
      //返回图片dataURL，参数：图片格式和清晰度(0-1)
      let pageData = canvas.toDataURL("image/jpeg", 1.0);
      console.log(leftHeight, pageHeight);
      //方向默认竖直，尺寸ponits，格式 a4纸 [595.28,841.89]
      //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
      //当内容未超过pdf一页显示的范围，无需分页
      // if (leftHeight < pageHeight) {
        //addImage将图片添加到pdf中
        //addImage中间两个参数控制x、y边距，
        //后两个参数控制添加图片的尺寸，此处将页面高度按照a4纸宽高比列进行压缩
        PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
      // } else {
      //   while (leftHeight > 0) {
      //     PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
      //     leftHeight -= pageHeight;
      //     position -= 841.89;
      //     //避免添加空白页
      //     if (leftHeight > 0) {
      //       //addPage()添加pdf页数
      //       PDF.addPage();
      //     }
      //   }
      // }
      this.setTimeCount++;
      PDF.save("进仓单.pdf");
      this.btnLoading = false;
    },
    handleCopyTxt(text){
      const input = document.createElement("textarea");
      document.body.appendChild(input);
      input.value = text;
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.$message.success("复制成功");
    },
    hadleCopy (tit,email,tel){
      this.dialogMsg={
        title:tit,
        tel: tel,
        email:email
      },
      console.log(tit,email,tel);
      this.userMsgDialog_show=true
    },
    // async time_change(e){
    //   console.log(new Date(e).getSeconds());
      
    //   let params={
    //     business_order_details_id:this.$route.query.id,
    //     expected_arrival_date:this.$moment(e).unix()
    //   }
    //   let res=await expected_arrival_date(params)
    // },
    async getHorseMsg(){  //内装仓库
      this.loading = true
      let res=await this.$store.dispatch("baseConsole/getInteriorWarehouse",{order_id:this.$route.query.id })
      if (res.success) {
        this.loading = false;
        if (res.data) {
          this.hourse_msg = res.data.inner;
          this.inroom_msg = res.data.outer;
        }
      }
    },
    handleClick(tab, event) {
      this.uploadingFileDialog = false;
      this.getList();
    },
    recordLog(ossId){
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: ossId,
        content: JSON.stringify({
          id: this.$route.query.id || ''
        }),
      });
    },
    getList() {
      this.loading = true;
      if (this.activeName === "first") { // 基本和订舱
        this.recordLog('ymp.workbench.shippingOrder.base');
        this.getFirstData();
      }
      if (this.activeName === "second") { // 提单查询
        this.recordLog('ymp.workbench.shippingOrder.td');
        this.getSeaLadingDetail();
      }
      if (this.activeName === "third") { // 费用
        this.recordLog('ymp.workbench.shippingOrder.free');
        this.getThirdInfo();
      }
      if (this.activeName === "fourth") { // 文件
        this.recordLog('ymp.workbench.shippingOrder.file');
        this.getFourthInfo();
      }
      if (this.activeName === "bill") { // 账单费用
        this.recordLog('ymp.workbench.shippingOrder.bill');
        this.$refs.billRef.fillData(this.firstData);
        this.loading = false;
      }
      if (this.activeName === "cargoSpecial") { // 特殊货物
        this.recordLog('ymp.workbench.shippingOrder.cargoSpecial');
        this.getSeaSpecialGoodsFunc();
      }
      if (this.activeName === "fifth") { // 仓库内装
        this.recordLog('ymp.workbench.shippingOrder.warehouse');
        this.getHorseMsg();
      }
      if (this.activeName === "last") { // 箱信息
        this.recordLog('ymp.workbench.shippingOrder.box');
        this.getPackInfo();
      }
    },
    async getPackInfo() { // 箱信息
      let params = {
        order_id: this.$route.query.id,
        spell_type: this.$route.query.spell_type,
      };
      let res = await this.$store.dispatch("baseConsole/getSeaContainerList", params);
      this.loading = false;
      if (res.success) {
        this.packList = res.data;
      }
    },
    async getFirstData() { // 基本 订舱
      let params = {
        order_id: this.$route.query.id,
      };
      let res = await this.$store.dispatch(
        "baseConsole/getSeaBasicInfo",
        params
      );
      this.loading = false;
      if (res.success) {
        res.data.shipper ? res.data.shipper = JSON.parse(res.data.shipper):[]
        res.data.consignee ? res.data.consignee = JSON.parse(res.data.consignee):[]
        res.data.notify_party ? res.data.notify_party = JSON.parse(res.data.notify_party):[]
        this.firstData = res.data;
        this.$refs.customerMessageRef.fillData(res.data);
        this.$emit("upEtd", res.data.job_no);
      }
    },
    async getSeaLadingDetail() { // 提单信息
      let havMbl = this.firstData.local_service.indexOf('6') > -1 ? true:false;
      let havHbl = this.firstData.local_service.indexOf('7') > -1 ? true:false;
      let bill_kind = 0;
      if(havHbl){
        bill_kind = 2 // 提单分类：1-MBL；2-HBL
      }else if(havMbl){
        bill_kind = 1 // 提单分类：1-MBL；2-HBL
      }else{
        this.lad = null
        this.loading = false;
        return;
      }
      let params = {
        order_id: this.$route.query.id,
        // bill_kind: this.firstData.spell_type != 2 ? 1: this.firstData.is_master == 1 ? 1:2
        bill_kind
      };
      let res = await this.$store.dispatch(
        "baseConsole/getSeaLadingDetail",
        params
      );
      this.loading = false;
      if (res.success) {
        res.data.shipper = res.data.shipper ? JSON.parse(res.data.shipper): {}
        res.data.consignee = res.data.consignee ? JSON.parse(res.data.consignee): {}
        res.data.notify_party = res.data.notify_party ? JSON.parse(res.data.notify_party): {}
        this.lad = res.data;
        this.ruleForm.shipper = this.lad.shipper
        this.ruleForm.consignee = this.lad.consignee
        this.ruleForm.notify_party = this.lad.notify_party
      }
    },
    async getThirdInfo() {
      let params = {
        order_id: this.$route.query.id,
        nopage:1,
        type:1 //费用类型：1-应收，2-应付
      };
      let res = await this.$store.dispatch(
        "baseConsole/getSeaFeeList",
        params
      );
      let queryString = Object.assign(params,{type:2})
      let payable = await this.$store.dispatch(
        "baseConsole/getSeaFeeList",
        queryString
      );
      this.loading = false;
      if (res.success) {
        // 应收费用
        this.thirdData.receivable_list = res.data;
      }
      if (payable.success) {
        // 应收费用
        this.thirdData.payable_list = payable.data;
      }
    },
    async getSeaSpecialGoodsFunc() { // 特殊货物
      let params = {
        order_id: this.$route.query.id,
        nopage: 1
      };
      let res = await this.$store.dispatch(
        "baseConsole/getSeaSpecialGoods",
        params
      );
      this.loading = false;
      if (res.success) {
        this.specialGoodsList = res.data;
      }
    },
    async getFourthInfo() { // 文件
      let params = {
        order_id: this.$route.query.id,
        nopage: 1
      };
      let res = await this.$store.dispatch(
        "baseConsole/getBookingbusinessOrderFilesList",
        params
      );
      this.loading = false;
      if (res.success) {
        this.fourthData = res.data;
      }
    },
    async viewRow(special_goods_id){
      this.spd = {};
      let res = await this.$store.dispatch(
        "baseConsole/getSeaSpecialGoodsDetail",
        {special_goods_id:special_goods_id}
      );
      if (res.success) {
        this.spd = res.data;
        this.dialogFormVisible = true;
      }else{
        this.$message.error('查询失败!')
      }
    },
    deleteRow(rel_id) {
      this.$confirm(`是否删除该文件，删除后不可恢复`, "删除文件", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      })
      .then(async () => {
          let res = await this.$store.dispatch("baseConsole/seaOrderFileDelete",{ rel_id: rel_id }
        );
          if(res.success){
              this.$message.success("操作成功");
              this.getList();
          }
          })
      .catch(() => {});
    },
    // 上传资料
    handleAddDataBank() {
      this.uploadingFileDialog = true;
    },
    // 下载文件
    handle_open(url) {
      window.open(url, "", "", false);
    },
    handleCloseDialog() {
      this.uploadingFileDialog = false;
      this.getList();
    },
  },
  computed:{
    totalNo: function () {
      // `this` 指向 vm 实例
      let no = 0;
      this.packList.forEach((res) => {
        no = no + res.entrust_quantity;
      });
      return no;
    },
    totalWeight: function () {
      let no = 0;
      this.packList.forEach((res) => {
        no = no + res.entrust_gross_weight;
      });
      return no;
    },
    totalVolum: function () {
      let no = 0;
      this.packList.forEach((res) => {
        no = no + res.entrust_volume;
      });
      return no;
    },
  }
};
</script>

<style scoped lang="less">
.showhtml{
  display: block;
}
.hidehtml{
  display: none;
}
.nomsg{
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.f_tips{
  padding-left: 56px;
  padding-bottom: 30px;
}
.roomBox{
  border: 1px solid #c0c4cc;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
  .boxTit{
    position: absolute;
    top: -18px;
    left: 20px;
    padding: 10px;
    background: #fff;
  }
  .row_flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .item {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .label {
        width: 130px;
        font-weight: 500;
      }
      .value {
        flex: 1;
      }
    }
}
.img_scll{
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .jiantou{
    font-size:25px;
    text-align: center;
    line-height: 50px;
    font-weight: bold;
    width: 50px;
    height: 50px;
  }
  .jiantou :hover{
    font-size:25px;
    text-align: center;
    line-height: 50px;
    font-weight: bold;
    width: 50px;
    height: 50px;
    background-color: rgba(31,45,61,.11);
    border-radius:50%;
    cursor: pointer;
  }
  .jiantou_disabled{
    font-size:25px;
    text-align: center;
    line-height: 50px;
    font-weight: bold;
    cursor: not-allowed;
  }
  .img_list{
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    div{
      // cursor: pointer;
    }
  }
}
.tabs {
  /deep/ .el-tabs__nav-wrap {
    .el-tabs__item {
      padding: 0 12px;
      height: 48px;
      line-height: 48px;
      font-size: 16px;

      &.is-active {
        font-weight: 700;
      }
    }

    &::after {
      height: 1px;
      background-color: #e4e7ed;
      z-index: 1;
    }
  }
}
.content {
  min-height: 500px;
}
.tabsItem {
  display: flex;
  justify-content: space-between;

  .box-card {
    width: 33%;
    .item {
      width: 100%;
      display: flex;
      margin-bottom: 30px;
      .label {
        width: 100px;
        font-weight: 500;
      }
      .value {
        flex: 1;
      }
    }
    .item2 {
      border: 1px solid #cccccc;
      margin: 20px 0;
      div {
        padding: 4px 10px;
        border-bottom: 1px solid #cccccc;
      }
      div:last-child {
        border-bottom: none;
      }
    }
  }
  .width39 {
    width: 39%;
  }
  .width60 {
    width: 60%;
  }
  .width100 {
    width: 100%;
  }
}
.line {
  width: 100%;
  height: 1px;
  background: #c0c4cc;
  margin: 10px 0;
}
.nopic{
  height: 100px;
  line-height: 100px;
  text-align: center;
  color: #909399;
  font-size: 12px;
}
.copy_text{
  display: flex;
  justify-content: space-between;
}
.item_msg{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  i{
    font-size: 23px;
  }
  span{
    // width:200px;
    padding: 0 20px;
  }
}
.mainColor{
  color: #0083f6 !important;
  cursor: pointer;
}
.lading, .total{
  line-height: 36px;
  padding: 10px;
}
.lable_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-bottom: 15px;
}
.rows{
  display: flex;
  justify-content: space-between;
  .item{
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    .label{
      // width: 150px;
      margin-right: 30px;
      font-weight: 500;
    }
    .value{
      flex: 1;
    }
  }
}
</style>